import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"

import {
  Col,
  Container,
  Figure,
  Row,
  Spacer,
  Typography,
  media,
  AspectRatio,
} from "@hurleymc/components"
import { AmazonCharityBanner } from "./amazon-charity-banner"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/page-builder"

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
function hexToRgbA(hex, opacity = 1) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    )
  }
  console.error("bad hex")
  return null
}

const TextBox = styled.div`
  align-items: center;
  background: radial-gradient(
    ellipse at center,
    ${({ theme }) => hexToRgbA(theme.colors.primary, 0)} 0%,
    ${({ theme }) => hexToRgbA(theme.colors.primary, 0.4)} 100%
  );
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem;

  ${media.breakpoint.up(
    "lg",
    css`
      background: none;
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    `
  )}
`

const Donate = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  const image =
    entry.hfImage && entry.hfImage.length > 0 && entry.hfImage[0].lg
      ? entry.hfImage[0].lg
      : null
  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      {(entry.storyStatement ||
        (entry.hfImage && entry.hfImage.length > 0)) && (
        <AspectRatio xs={false} lg={true} ratio="9:4">
          {entry.hfImage && entry.hfImage.length > 0 && (
            <Figure {...entry.hfImage[0]} />
          )}
          {entry.storyStatement && (
            <TextBox>
              <Typography variant="heading-1">
                {entry.storyStatement}
              </Typography>
            </TextBox>
          )}
        </AspectRatio>
      )}
      <Spacer margin="4rem .5rem 2rem">
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col md={5 / 6}>
              <Typography variant="heading-3">{entry.header}</Typography>
            </Col>
          </Row>
          <Row xsJustifyContentCenter={true}>
            <Col md={5 / 6}>{/* <DonationForm /> */}</Col>
          </Row>
        </Container>
      </Spacer>
      {entry && entry.pageBuilder && (
        <>
          <PageBuilder
            pageBuilder={entry.pageBuilder}
            location={{ pathname: entry.uri }}
          />
          {/* <div>
            <AmazonCharityBanner />
          </div> */}
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query Donate($id: [Craft_QueryArgument]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_hfDonate_hfDonate_Entry {
          hfImage {
            ... on Craft_AssetInterface {
              title
              url
              lg: url(transform: "splashLg", immediately: true)
              xl: url(transform: "splashXl", immediately: true)
            }
          }
          storyStatement
          header
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default Donate
